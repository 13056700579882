
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { EventDetailClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import { createPreviewFromImage, getUniversalDate, getUniversalISOStringDate } from '@/utility';
import sendPushModal from '@/components/modals/sendPushModal.vue';
import invitaUtentiModal from './invitaUtentiModal.vue'

@Options({})
export default class Events extends Vue {

    eventIdentifier: string = "";
    event: OM.EventDetail = new OM.EventDetail();
    file: File = null;

    tempGenre: string = "";

    created(){
        this.eventIdentifier = this.$route.params.identifier as string;

        this.init();
    }

    init(){
        if(!this.eventIdentifier)
            return;

        EventDetailClient.getById(this.eventIdentifier)
        .then( x => {
            this.event = x;
        })
    }

    save(){

        var toSave = JSON.parse(JSON.stringify(this.event));
        // toSave.startDate = getUniversalISOStringDate(this.event.startDate);
        // toSave.endDate = getUniversalISOStringDate(this.event.endDate);

        EventDetailClient.save(toSave)
        .then( x => {
            EventDetailClient.updateFlyer( x, this.file)
            .then( () => {
                this.event.identifier = x;
                this.$router.replace("/events/" + x);
            })
        })
    }

    addGenre(){
        this.tempGenre.trim();

        if(!this.tempGenre)
            return;

        var searchGenre = this.tempGenre.toLocaleLowerCase();

        if(this.event.genres.find( x => x.toLocaleLowerCase() == searchGenre) != null)
            return;

        this.event.genres.push(this.tempGenre);
        this.tempGenre = "";
    }

    removeGenre(index: number){
        this.event.genres.splice(index, 1)
    }

    async imageChanged(file: File){
        if(!file)
            return;

		this.file = file;

		var preview = await createPreviewFromImage(file);
        this.event.flyer = this.event.flyer || new OM.PublicUrlAttachment();
		this.event.flyer.publicUrl = preview;
	}

    get flyerImage(){
        if(this.event.flyer && this.event.flyer.publicUrl)
            return this.event.flyer.publicUrl;

        return "";
    }

    get isDisabled(){
        return !this.event.title.trim() || !this.event.subTitle.trim() || !this.event.description.trim() || !this.event.location || !this.event.address.completeAddress;
    }

    publishQuestion(){
        if(!this.eventIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerPubblicareQuestoEvento", "Sei sicuro di voler pubblicare questo evento?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.publish();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    publish(){
        EventDetailClient.publishById(this.eventIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_PubblicatoConSuccesso", "Pubblicato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    deleteQuestion(){
        if(!this.eventIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareQuestoQuestionario", "Sei sicuro di voler eliminare questo questionario?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.delete();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    delete(){
        EventDetailClient.deleteById(this.eventIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_EliminatoConSuccesso", "Eliminato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$router.replace("/events");
                    this.$router.push("/events-list")
                    this.$opModal.closeLast();
                }
            })
        })
    }

    hideQuestion(){
        if(!this.eventIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerNascondereQuestoEvento", "Sei sicuro di voler nascondere questo evento?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.hide();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    hide(){
        EventDetailClient.hideById(this.eventIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_NascostoConSuccesso", "Nascosto con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    openPushModal(){
        this.$opModal.show(sendPushModal, {
            users: this.event.signedUpUsers,
            route: "/eventDetail/" + this.event.identifier
        })
    }

    openInvitaUtentiModal(){
        this.$opModal.show(invitaUtentiModal, {
            selectedUsers: this.event.invitedUsers,
        })
    }

}
