import store from "./store";
import * as OM from "./Model";

export function shuffleArray(list: any[]): any[]{
    var ris = list
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    return ris;
}

export function createPreviewFromImage(file) : Promise<string> {
    let prom = new Promise<string>((resolve, reject) => {
        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }
        
        var reader = new FileReader();

        reader.onload = (e:any) => {
            resolve(e.target.result);
        }
        reader.readAsDataURL(file);
    })

    return prom;
}

export function getUniversalISOStringDate(input: any): string {
    var data = input;
    
    if(typeof input == typeof "")
        data = new Date(input);

    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {

    var data = input;

    if(typeof input == typeof "")
        data = new Date(input);

    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function insertAllLanguages(localized: OM.LocalizedEntity){
    if(!localized)
        localized = new OM.LocalizedEntity();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
} 