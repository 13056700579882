
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { AppUserClient, NotificationClient } from '@/services/Services';

@Options({})
export default class invitaUtentiModal extends Vue {

    @Prop() selectedUsers: string[]

    items: OM.UserBasicInfoVM[] = [];

    created(){
        AppUserClient.getAllAppUsers(null)
        .then(x => {
            this.items = x;
        })
    }

    isSelected(item: OM.UserBasicInfoVM){
        let indexOf = this.selectedUsers.findIndex(x => x == item.identifier);
        return indexOf > -1;
    }

    toggleUser(item: OM.UserBasicInfoVM){
        let indexOf = this.selectedUsers.findIndex(x => x == item.identifier);
        if(indexOf > -1){
            this.selectedUsers.splice(indexOf, 1);
        } else {
            this.selectedUsers.push(item.identifier);
        }
    }

}
